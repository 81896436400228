<template>
  <div class="stars-widget-container" :style="itemStyles">
    <div
      class="stars-content"
      v-for="n in iconsLength"
      :key="n"
      @mouseover="hoverScore = n"
      @mouseout="hoverScore = -1"
      @click="onClick(n)"
    >
      <div
        class="active-icon-item"
        v-show="activeScore >= n || hoverScore >= n"
        v-html="activeIconSVG"
      />
      <div
        class="icon-item"
        v-show="!(activeScore >= n || hoverScore >= n)"
        v-html="iconSVG"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import setStyles from "@/utils/setStyles";
import { updateRespond } from "@/utils/clickAction";

export default {
  name: "SvStarsWidget",
  props: ["widgetIdx"],
  components: {},
  data() {
    return {
      activeScore: null,
      hoverScore: false,
      activeIconSVG: "",
      iconSVG: "",
    };
  },
  computed: {
    ...mapGetters([
      "activeStep",
      "interaction",
      "step",
      "respond",
      "interactionId",
      "respondId",
    ]),
    widget() {
      return this.activeStep?.objects?.[this.widgetIdx];
    },
    itemStyles() {
      return {
        "--active-icon-color": this.widget?.configs?.activeIconColor,
        "--icon-color": this.widget?.configs?.iconColor,
        ...setStyles(this.widget),
      };
    },
    iconsLength() {
      return this.widget?.configs?.iconsLength ?? 0;
    },
    activeImg() {
      return this.widget?.configs?.activeIconUrl;
    },
    img() {
      return this.widget?.configs?.iconUrl;
    },
  },
  async mounted() {
    this.activeIconSVG = await fetch(this.activeImg).then((d) => d.text());
    this.iconSVG = await fetch(this.img).then((d) => d.text());
    this.$emit("hideLoader");
  },
  methods: {
    onClick(value) {
      this.activeScore = value;
      this.$store.dispatch("updateRespond", {
        data: {
          // this.filesList,
          widgetType: "stars",
          widgetName: "stars",
          actionType: "stars",
          actionData: this.activeScore,
        },
        idx: this.widgetIdx,
        type: "stars",
        step: this.step,
      });
      updateRespond();
    },
  },
};
</script>

<style scoped>
.stars-widget-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 40px;
}
.stars-content {
  cursor: pointer;
}
.active-icon-item {
  display: flex;
  width: 40px;
  fill: var(--active-icon-color);
  padding: 0 7px 7px 0;
}
.active-icon-item >>> svg,
.icon-item >>> svg {
  width: 100%;
  height: 100%;
}
.icon-item {
  padding: 0 7px 7px 0;
  display: flex;
  width: 40px;
  fill: var(--icon-color);
}
</style>
